import React, { useContext } from "react"
import { ThemeContext } from "../app/store";
import '../css/app.scss'

const Layout = ({ children }) => {

  const [theme] = useContext(ThemeContext)

  return (
    <main style={{
      backgroundColor: theme ? theme.background : '#FFFFFF',
      color: theme ? theme.foreground : '#000000',
      transition: '0.5s'
    }}>
      {children}
    </main>
  )
}

export default Layout
