import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

export const sample = arr => arr[Math.floor(Math.random() * arr.length)];

export const ThemeContext = React.createContext({
  background: '#FFFFFF',
  foreground: '#000000'
});
export const DataContext = React.createContext({})

const Store = ({children}) => {

  const fetchContent = useStaticQuery(graphql`
    query AllContentQuery {
      allContentfulSettings{
        edges {
          node {
            ...Settings
          }
        }
      }
    }
  `)

  const content = fetchContent.allContentfulSettings.edges[0].node

  const [theme, setTheme] = useState(sample(content.colours))
  const [data, setData] = useState(content)

  return (
    <DataContext.Provider value={[data, setData]}>
      <ThemeContext.Provider value={[theme, setTheme]}>
        {children}
      </ThemeContext.Provider>
    </DataContext.Provider>
  )
};

export default Store;

export const settingsFragment = graphql`
  fragment Settings on ContentfulSettings {
    siteTitle
    siteDescription
    aboutCopy {
      childMarkdownRemark {
        html
      }
    }
    contactCopy {
      childMarkdownRemark {
        html
      }
    }
    colours {
      background
      foreground
    }
    instagram
    selectedProjects {
      title
      slug
      thumbnail {
        fluid(maxWidth: 780, quality: 90) {
          sizes
          src
          srcSet
          aspectRatio
        }
      }
      coverImage {
        fluid(maxWidth: 780, quality: 90) {
          sizes
          src
          srcSet
          aspectRatio
        }
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      heading {
        childMarkdownRemark {
          html
        }
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      colour {
        background
        foreground
      }
    }
  }
`
